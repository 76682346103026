

















import BaseForm from '@/components/common/base-form/index.vue';
import PageLayout from '@/components/page/layout.vue';
import { createAreaItemModule, IArea } from '@/store/areas/areas';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RawLocation } from 'vue-router';
import formSchema from './form.schema';

@Component({
  name: 'area-form',
  components: { PageLayout, BaseForm }
})
export default class AreaForm extends Vue {
  private readonly module = createAreaItemModule(this.$store);

  get state() {
    return this.module.state;
  }

  get schema() {
    return formSchema;
  }

  created() {
    this.load();
  }

  load() {
    const { item, id } = this.$route.params;
    if (item) {
      this.state.setItemsState(item as any as IArea);
    } else if (id) {
      this.state.get(id);
    }
  }

  beforeDestroy() {
    this.module.unreg();
  }

  async saveHandler() {
    const isFormValid = await (this.$refs.baseForm as BaseForm)?.validate();
    const { item } = this.state;
    let result;

    if (isFormValid) {
      try {
        if (item?.id) {
          result = await this.state.update({ ...item }); //@todo: remove. filled cameras occur Server Error 500
        } else {
          result = await this.state.create();
          await this.$router.replace(this.state.getItemRoute() as RawLocation);
        }
        this.$notify({ type: 'success', message: this.$tf('action | success') });
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      }
    } else {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: this.$tf('error.form.validation') } }) });
    }

    return result;
  }
}
