

















import Vue from 'vue';
import Component from 'vue-class-component';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import AreaForm from '@/components/areas/areas/form/index.vue';
import { AreasModule } from '@/store/areas/areas';
import { AreaTriggersModule } from '@/store/areas/triggers';
import { areaTriggersModule, areaRecordsModule, areasModule } from '@/store';
import { TableComponentNames } from '@/components/tables/components/names';
import { Watch } from 'vue-property-decorator';
import HeaderEditDeleteButtons from '@/components/common/header.edit.delete.buttons.vue';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
  { label_i18n: 'image', prop: '', component: TableComponentNames.AreaTriggerPolygon, width: 160 },
  { label_i18n: 'description', prop: '', component: TableComponentNames.AreaTriggerDescription, 'min-width': 240 },
  { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 160 }
];

@Component({
  components: { HeaderEditDeleteButtons, AreaForm, TableNavigation, PageLayout, TableGenerator }
})
export default class AreaTriggers extends Vue {
  get areasState(): AreasModule {
    return areasModule;
  }

  get state(): AreaTriggersModule {
    return areaTriggersModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  get area() {
    let area = this.state?.filter?.current?.area;
    if (area) {
      return Array.isArray(area) ? area[0] : area;
    }
    return '';
  }

  get title() {
    return this.$tf('area_trigger_activators', { area: this.area });
  }

  @Watch('title')
  titleChanged(title) {
    this.$store.state.dialog.reports.i18nData = { area: this.area };
    document.title = title;
  }

  editHandler() {
    this.$router.push({ name: 'areasEdit', params: { id: this.area } });
  }

  deleteHandler() {
    this.$confirm(this.$tf('area_delete_confirm'), this.$tf('common.warning'), {
      confirmButtonText: this.$tf('common.yes'),
      cancelButtonText: this.$tf('common.cancel'),
      type: 'warning'
    })
      .then(() => {
        this.areasState.delete(this.area).then(() => {
          this.$router.push({ name: 'areas' });
        });
      })
      .catch(() => {});
  }

  mounted() {
    this.state.setAutoUpdate(true);
  }

  beforeDestroy() {
    this.state.setAutoUpdate(false);
  }

  rowClickHandler({ item }) {
    const route = areaRecordsModule.getListRoute({ area_trigger: [item.id] });
    this.$router.push(route);
  }
}
