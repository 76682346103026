











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'header-edit-delete-buttons'
})
export default class RouteButtonsBar extends Vue {
  @Prop({ type: Boolean, default: true })
  showDelete = true;

  @Prop({ type: Boolean, default: true })
  showEdit = true;
}
